<template>
  <v-icon v-if="howlerState.state == 'running'" color="green">
    mdi-volume-high
  </v-icon>
  <v-icon v-else color="red" @click="changeState"> mdi-volume-mute </v-icon>
</template>
<script setup>
import { ref } from "vue";
import { Howler } from "howler";

const howlerState = ref(Howler);

const changeState = () => {
  howlerState.value.state = "running";
};
// await changeState();
Howler.volume(1);

watch(howlerState, async (newState) => {
  //console.log(newState);
  howlerState.value = newState;
});
</script>
