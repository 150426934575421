<template>
  <div>
    <nav class="flex flex-1 flex-col">
      <ul role="list" class="-mx-2 space-y-1">
        <li v-for="item in navigation" :key="item.name">
          <div
            v-if="
              item.name == 'รายการฝาก-ถอน' || item.name == 'รายการฝาก-ถอน เอเจน'
            "
          >
            <a
              v-if="item.role.includes(userStore.user.role)"
              :href="item.href"
              :class="[
                item.current
                  ? 'bg-red-700 text-white'
                  : 'text-gray-700 hover:text-white hover:bg-gray-700',
                'group flex gap-x-3 rounded-md p-2 text-lg leading-6',
              ]"
            >
              <component
                :is="item.icon"
                :class="[
                  item.current
                    ? 'text-white'
                    : 'text-gray-700 group-hover:text-white',
                  'h-6 w-6 shrink-0',
                ]"
                aria-hidden="true"
              />
              <AdminNotiBadge
                :channel_name="
                  roleAgent.includes(userStore.user.role) ? 'agent' : 'admin'
                "
                :event_name="
                  item.name == 'รายการฝาก-ถอน'
                    ? 'cash-request'
                    : 'cash-agent-request'
                "
              >
                {{ item.name }}
              </AdminNotiBadge>
            </a>
          </div>
          <div v-else>
            <a
              v-if="item.role.includes(userStore.user.role)"
              :href="item.href"
              :class="[
                item.current
                  ? 'bg-red-700 text-white'
                  : 'text-gray-700 hover:text-white hover:bg-gray-700',
                'group flex gap-x-3 rounded-md p-2 text-lg leading-6',
              ]"
            >
              <component
                :is="item.icon"
                :class="[
                  item.current
                    ? 'text-white'
                    : 'text-gray-700 group-hover:text-white',
                  'h-6 w-6 shrink-0',
                ]"
                aria-hidden="true"
              />
              {{ item.name }}
            </a>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { set } from "@vueuse/core";
import {
  HomeIcon,
  ClipboardIcon,
  CreditCardIcon,
  UserCircleIcon,
} from "@heroicons/vue/24/outline";

const config = useRuntimeConfig();
const base_api = config.public.apiUri;
const userStore = useUserStore();
const path = ref("");
var navigation = ref([]);
const count = ref("1");
const route = useRoute();
const roleAgent = ref(["agent", "subagent"]);

watch(
  () => ({
    path: route.path,
    name: route.name,
    meta: route.meta,
    matched: route.matched,
  }),
  (route) => {
    var arrayRoute = route.path.split("/");
    set(path, arrayRoute[2]);
    navigation = [
      {
        name: "dashboard",
        href: "/admin/dashboard",
        icon: ClipboardIcon,
        role: ["superadmin", "admin", "agent", "subagent"],
        current: path.value == "dashboard" ? true : false,
      },
      {
        name: "สมาชิก",
        href: "/admin/member",
        icon: UserCircleIcon,
        role: ["superadmin", "admin", "agent", "subagent"],
        current: path.value == "member" ? true : false,
      },
      {
        name: "เอเจน",
        href: "/admin/agent",
        icon: UserCircleIcon,
        role: ["superadmin", "admin"],
        current: path.value == "agent" ? true : false,
      },
      {
        name: "ออกผลเกมส์",
        href: "/admin/lotto",
        icon: UserCircleIcon,
        role: ["superadmin", "admin"],
        current: path.value == "lotto" ? true : false,
      },
      {
        name: "ผลรางวัล",
        href: "/admin/reward",
        icon: CreditCardIcon,
        role: ["superadmin", "admin", "agent", "subagent"],
        current: path.value == "reward" ? true : false,
      },
      {
        name: "รายการฝาก-ถอน",
        href: "/admin/cash",
        icon: CreditCardIcon,
        role: ["agent", "subagent"],
        current: path.value == "cash" ? true : false,
      },
      {
        name: "รายการฝาก-ถอน เอเจน",
        href: "/admin/cash-admin",
        icon: CreditCardIcon,
        role: ["superadmin", "admin", "agent", "subagent"],
        current: path.value == "cash-admin" ? true : false,
      },
      {
        name: "รายการฝากตรง",
        href: "/admin/direct",
        icon: CreditCardIcon,
        role: ["superadmin", "admin", "agent", "subagent"],
        current: path.value == "direct" ? true : false,
      },
      {
        name: "รายการเครดิต",
        href: "/admin/credit",
        icon: CreditCardIcon,
        role: ["agent", "subagent"],
        current: path.value == "credit" ? true : false,
      },
      {
        name: "รายงานเครดิตเอเจน",
        href: "/admin/credit-agent",
        icon: CreditCardIcon,
        role: ["superadmin", "admin", "agent", "subagent"],
        current: path.value == "credit-agent" ? true : false,
      },
      {
        name: "สรุปยอดรายเกมส์",
        href: "/admin/report-lotto",
        icon: CreditCardIcon,
        role: ["superadmin", "admin", "agent", "subagent"],
        current: path.value == "report-lotto" ? true : false,
      },
      {
        name: "รายงานยอดแทง",
        href: "/admin/report-bet",
        icon: CreditCardIcon,
        role: ["superadmin", "admin", "agent", "subagent"],
        current: path.value == "report-bet" ? true : false,
      },
      {
        name: "รายงานคอมมิชชั่น",
        href: "/admin/commission",
        icon: CreditCardIcon,
        role: ["superadmin", "admin", "agent", "subagent"],
        current: path.value == "commission" ? true : false,
      },
      {
        name: "ผู้ช่วยเอเจน",
        href: "/admin/sub-agent",
        icon: UserCircleIcon,
        role: ["agent"],
        current: path.value == "sub-agent" ? true : false,
      },
      {
        name: "ประกาศ",
        href: "/admin/announce",
        icon: CreditCardIcon,
        role: ["superadmin", "admin", "agent", "subagent"],
        current: path.value == "announce" ? true : false,
      },
    ];
  },
  { deep: true, immediate: true }
);
</script>
