<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="relative z-50 lg:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  class="absolute left-full top-0 flex w-16 justify-center pt-5"
                >
                  <button
                    type="button"
                    class="-m-2.5 p-2.5"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div
                class="flex grow flex-col gap-y-2 overflow-y-auto bg-yellow-500 px-6 pb-4"
              >
                <div class="flex h-16 shrink-0 items-center">
                  <img
                    class="h-10 w-auto"
                    src="~assets/images/logo/dlto.svg"
                    alt="decho"
                  />
                  <!-- <span class="text-4xl font-weight-bold">deelotto</span> -->
                </div>
                <div v-if="role == 'agent' || role == 'subagent'">
                  <h4>credit : {{ credit }}</h4>
                  <h4>aff : {{ aff }}</h4>
                </div>
                <AdminNavSide></AdminNavSide>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div
      class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="flex grow flex-col gap-y-2 overflow-y-auto bg-yellow-500 px-6 pb-4"
      >
        <div class="flex h-16 shrink-0 items-center">
          <img
            class="h-10 w-auto"
            src="~assets/images/logo/dlto.svg"
            alt="deelotto"
          />
          <!-- <span class="text-4xl font-weight-bold">deelotto</span> -->
        </div>
        <div v-if="role == 'agent' || role == 'subagent'">
          <h4>credit : {{ credit }}</h4>
          <h4>aff : {{ aff }}</h4>
        </div>
        <AdminNavSide></AdminNavSide>
      </div>
    </div>

    <div class="lg:pl-72">
      <div
        class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8"
      >
        <button
          type="button"
          class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>

        <!-- Separator -->
        <div class="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

        <div
          class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 justify-end align-center"
        >
          <div class="flex items-center gap-x-4 lg:gap-x-6">
            <ClientOnly>
              <AdminSound />
            </ClientOnly>
            <!-- Profile dropdown -->
            <Menu as="div" class="relative">
              <MenuButton class="-m-1.5 flex items-center p-1.5">
                <span
                  class="mr-4 text-2xl font-semibold leading-6 text-gray-900"
                  aria-hidden="true"
                  >{{ userStore.user ? userStore.user.username : "" }}</span
                >
                <span
                  class="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100"
                >
                  <svg
                    class="h-full w-full text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                </span>
              </MenuButton>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                >
                  <MenuItem
                    v-for="item in userNavigation"
                    :key="item.name"
                    v-slot="{ active }"
                  >
                    <a
                      :href="item.href"
                      :class="[
                        active ? 'bg-gray-50' : '',
                        'block px-3 py-1  leading-6 text-gray-900',
                      ]"
                      @click="item.name == 'ออกจากระบบ' ? signOut() : ''"
                      >{{ item.name }}
                    </a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <main>
        <div class="mx-2 py-4 border-b">
          <span class="text-2xl">{{ title }}</span>
        </div>

        <div class="py-2 px-2 md:px-4 lg:px-8">
          <!-- Your content -->
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
useHead({
  title: "deelotto",
  meta: [
    {
      name: "viewport",
      content:
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no",
    },
  ],
  meta: [{ name: "title", content: "deelotto" }],
  meta: [{ name: "description", content: "deelotto เว็บหวย" }],
});
import { ref } from "vue";
import { set } from "@vueuse/core";
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  CreditCardIcon,
} from "@heroicons/vue/24/outline";
import { UserIcon, UserCircleIcon } from "@heroicons/vue/24/solid";
import { ChevronDownIcon, MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
const userStore = useUserStore();
const { showCredit } = useUtils();
const config = useRuntimeConfig();
const base_api = config.public.apiUri;
var title = ref("");
const credit = ref(0);
const aff = ref(0);
const route = useRoute();
const role = userStore.user.role;
watch(
  () => ({
    path: route.path,
    name: route.name,
    meta: route.meta,
    matched: route.matched,
  }),
  (route) => {
    var arrayRoute = route.path.split("/");
    switch (arrayRoute[2]) {
      case "dashboard":
        set(title, "ข้อมูลภาพรวม");
        break;
      case "member":
        set(title, "สมาชิก");
        break;
      case "agent":
        set(title, "เอเจน");
        break;
      case "lotto":
        set(title, "ออกผลเกมส์");
        break;
      case "reward":
        set(title, "ผลรางวัล");
        break;
      case "cash":
        set(title, "รายการฝาก-ถอน");
        break;
      case "cash-admin":
        set(title, "รายการฝาก-ถอน เอเจน");
        break;
      case "bet":
        set(title, "รายการโพย");
        break;
      case "credit-agent":
        set(title, "รายงานเครดิตเอเจน");
        break;
      case "credit":
        set(title, "รายการเครดิต");
        break;
      case "report-daily":
        set(title, "สรุปยอดรายวัน");
        break;
      case "report-lotto":
        set(title, "สรุปยอดรายเกมส์");
        break;
      case "report-bet":
        set(title, "รายงานยอดแทง");
        break;
      case "commission":
        set(title, "รายงานคอมมิชชั่น");
        break;
      case "sub-agent":
        set(title, "ผู้ช่วยเอเจน");
        break;
      case "announce":
        set(title, "ประกาศ");
        break;
      case "bank":
        set(title, "จัดการบัญชี");
        break;
      case "profile":
        set(title, "ข้อมูลผู้ใช้");
        break;
      case "feedback":
        set(title, "แจ้งเตือน");
        break;
    }
  },
  { deep: true, immediate: true }
);

const userNavigation = [
  { name: "จัดการบัญชี", href: "/admin/bank" },
  { name: "เปลี่ยนรหัสผ่าน", href: "/admin/profile" },
  { name: "ออกจากระบบ", href: "#" },
];

const getCredit = async () => {
  const { data, error } = await useFetch(
    `${base_api}/agent/credit/current/${userStore.user._id}`,
    {
      key: "getCredit",
      server: true,
      headers: { Authorization: `Bearer ${userStore.token}` },
      method: "get",
    }
  );
  credit.value = showCredit(parseFloat(data.value));
  return data;
};

const getAff = async () => {
  const { data, error } = await useFetch(
    `${base_api}/agent/aff/current/${userStore.user._id}`,
    {
      key: "getAff",
      server: true,
      headers: { Authorization: `Bearer ${userStore.token}` },
      method: "get",
    }
  );
  aff.value = showCredit(parseFloat(data.value));
  return data;
};

if (role == "agent") {
  getCredit();
  getAff();
}
const signOut = async () => {
  var res = await userStore.signOut();
  if (!userStore.token) {
    await navigateTo({ path: "/admin/login" }, { replace: true });
  }
};

const sidebarOpen = ref(false);
</script>
<style>
body {
  font-family: "Sarabun", sans-serif;
  font-size: 0.9rem;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.8);
}
.dark-mode body {
  background-color: #091a28;
  color: #ebf4f1;
}
.sepia-mode body {
  background-color: #f1e7d0;
  color: #433422;
}

[type="checkbox"],
[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #007278;
  background-color: #ccc;
  border-color: rgb(var(--color-gray-500) / 1);
  border-color: rgb(var(--color-gray-500) / var(--tw-border-opacity, 1));
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}
select {
  -moz-appearance: auto;
  -webkit-appearance: auto;
}
.tiptap ol {
  list-style-type: decimal;
}
.tiptap ul li {
  list-style-type: disc;
}
.tiptap p {
  margin-bottom: 0px;
  margin-top: 0px;
}
</style>
